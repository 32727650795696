import React from "react";
import { ReadMoreText } from "../readmore/ReadMoreText";
import "./ticketBox.css";

const TicketBox = (props) => {

  return (
    <div>
      <div className="ticket__wrapper">
        <div className="ticket__content enabled">
          <div className="ticket__content__item rounded">
            <div className="card-box__item">
              <div className="card-box__item__profile">
                {/* <div className="d-flex justify-content-center"> */}
                <h5 className="card-box__item__name">
                  Ticket No:{props.ticketNo || "-"}
                </h5>
                <h4 className="card-box__item__name">
                  {props.ticketTitle || "Title Name"}
                </h4>
                {/* </div> */}
                <div className="card-box__item__experience">
                  <b>Description: </b>
                  <ReadMoreText>
                    {props.description || "-"}
                  </ReadMoreText>
                   

                  {/* <span>{props.description || "-"}</span> */}
                </div>
                <div className="d-flex justify-content-around">
                  <div className="card-box__item__location">
                    <b>Priority: </b>
                    {props.priority || "-"}
                  </div>
                  <div className="card-box__item__experience">
                    <b>Status: </b>
                    <span>{props.status || "-"}</span>
                  </div>
                </div>
                <div className="d-flex justify-content-around">
                  <div className="card-box__item__location">
                    <b>Location: </b>
                    {props.location || "-"}
                  </div>
                  <div className="card-box__item__location">
                    <b>Category: </b>
                    {props.category || "-"}
                  </div>
                </div>
              </div>
              <div
                className={`card-box__item__bottom card-box__item__bottom--${
                  props.status === "Closed"
                    ? "closed"
                    : props.status === "Waiting"
                    ? "waiting"
                    : props.status === "Open"
                    ? "open"
                    : "play"
                }`}
              >
                {/* <div>{props.ticketNo || }</div> */}
                <div>
                  <b>Assign To: </b>
                  <span>{props.assignTo || " "}</span>
                </div>
                <div>
                  <b>Created By: </b>
                  <span>{props.createdBy || " "}</span>
                </div>
                <div>
                  <b>Created On: </b>
                  <span>{props.createdOn || "No Date"}</span>
                </div>
                <div>
                  <b>Due Date: </b>
                  <span>{props.dueOn || "No Date"}</span>
                </div>
                <div>
                  <b>Closed On: </b>
                  <span>{props.closedOn || "No Date"}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketBox;
