import React, { useState, useEffect } from "react";
import "../login-signup-css/login-signup.css";
import "../login-signup-css/Animation.css";
import axios from "axios";
import { Link } from "react-router-dom";
import { url } from "../../components/baseUrl";
import { toast } from "react-toastify";
// import { jwt } from "jsonwebtoken";
// import jwt from "jsonwebtoken";
import jwt_decode from "jwt-decode";

const Login = () => {
  const [validated, setValidated] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  let domainName = "@macca.com.pk";
  const [userData, setuserData] = useState({
    email: "",
    password: "",
    isAdmin: 0,
  });

  const clear = () => {
    setuserData({
      email: "",
      password: "",
    });
  };

  // const t = localStorage.getItem("token");
  // // console.log("t ==>", t);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setIsLogin(false);
      return;
    }

    if (
      userData.username === "" &&
      userData.email === "" &&
      userData.password === ""
    ) {
      // console.log("Please fill out all fields");
      toast.warning("Please fill out all fields");
      return;
    }

    // // console.log("userData ====>", userData);
    setIsLogin(true);
    setValidated(true);
    let submitData = {
      email: userData.email + domainName,
      password: userData.password,
    };
    // // console.log("submitData ===>", submitData);

    axios({
      method: "POST",
      // data: userData,
      data: submitData,
      url: `${url}/user/login`,
    })
      .then((response) => {
        // // console.log("response from Login ====>", response);
        clear();
        let token = response.data.token;
        localStorage.setItem("token", response.data.token);
        var decoded = jwt_decode(token);
        // console.log(decoded);
        localStorage.setItem("username", decoded.username);

        // let token = jwt.decode(response.data.token);
        // // console.log(token);

        if (response.status === 201) {
          setIsLogin(false);
          window.location.reload(false);
          toast.success("Login successfully ");
        }
        if (localStorage.getItem("token")) {
          // console.log("token available ====>");
          // history.push("/home");
        } else {
          setIsLogin(false);
        }
      })
      .catch((error) => {
        setIsLogin(false);
        toast.error("Server Error ");
        // console.log("error from Login =====>", error);
      });
  };

  return (
    <div
      className="d-flex align-items-center justify-content-center bg-br-primary ht-100v backgroundImage"
      style={{ backgroundColor: "#eee" }}
    >
      <div className="login-wrapper wd-300 wd-xs-350 pd-25 pd-xs-40 bg-white rounded shadow-base">
        <div className="signin-logo tx-center tx-28 tx-bold tx-inverse">
          <span className="tx-normal">[</span> Macca{" "}
          <span className="tx-normal">]</span>
        </div>
        <div className="tx-center mg-b-60">Help Desk</div>
        <form
          className="row g-3 needs-validation"
          noValidate
          validated={validated}
          novalidate
          onSubmit={handleSubmit}
        >
          <div className="form-group mb-0">
            <label htmlFor="validationCustom02" className="form-label">
              Email
            </label>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Enter your email"
                required
                id="validationCustom02"
                value={userData.email}
                style={{ backgroundColor: "#eeee" }}
                onChange={(e) =>
                  setuserData({
                    ...userData,
                    email: e.target.value,
                  })
                }
              />
              <span
                className="input-group-text"
                id="basic-addon2"
                style={{
                  fontSize: "10px",
                  backgroundColor: "#9A2232",
                  color: "#FFFFFF",
                }}
              >
                @macca.com.pk
              </span>
            </div>
            <div className="valid-feedback">Looks good!</div>
          </div>
          {/* form-group */}
          <div className="form-group">
            <label htmlFor="validationCustom03" className="form-label">
              Password
            </label>
            <input
              type="password"
              className="form-control"
              placeholder="Enter your password"
              required
              id="validationCustom03"
              style={{ backgroundColor: "#eeee" }}
              value={userData.password}
              onChange={(e) =>
                setuserData({
                  ...userData,
                  password: e.target.value,
                })
              }
            />
            <div className="valid-feedback">Looks good!</div>
          </div>
          {/* <a href className="tx-info tx-12 d-block mg-t-10">
            Forgot password?
          </a> */}
          {isLogin ? (
            <svg
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              width={50}
              height={50}
              fill="none"
            >
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    "@keyframes loader2{0%{-webkit-transform:rotate(0);transform:rotate(0)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}",
                }}
              />
              <path
                fill="#265BFF"
                d="M17.179 13.605a.431.431 0 00.279.514l.775.245a.393.393 0 00.499-.268 7.318 7.318 0 00-4.5-8.658.39.39 0 00-.507.255l-.245.775a.43.43 0 00.261.523 5.69 5.69 0 013.438 6.614z"
                style={{
                  animation:
                    "loader2 1s cubic-bezier(.63,-.71,.32,1.28) infinite both",
                  transformOrigin: "center center",
                }}
              />
            </svg>
          ) : (
            <button
              type="submit"
              className="btn btn-info btn-block"
              style={{ backgroundColor: "#9A2232", borderColor: "#9A2232  " }}
            >
              Login
            </button>
          )}
        </form>

        {/* <div className="mg-t-60 tx-center">
          Not yet a member?{" "}
          <a href="/signup" className="tx-info">
            Sign Up
          </a>
        </div> */}
      </div>
      {/* login-wrapper */}
    </div>
  );
};

export default Login;
