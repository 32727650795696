import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Cards from "../../components/cards/Cards";
import { Button, Modal, Form, Row, Col, FloatingLabel } from "react-bootstrap";

import moment from "moment";

import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import Stack from "@mui/material/Stack";

import "./dashboard.css";
import { Link } from "react-router-dom";
import { url } from "../../components/baseUrl";
import { getFeedback } from "../../components/actions";
import { toast } from "react-toastify";
// import testCard from "../../components/cards/testCard";
// import TestCard from "../../components/cards/TestCard";\
import TestCard from "../../components/cards/TestCard";

const Dashboard = () => {
  const [show, setShow] = useState(false);

  const [validated, setValidated] = useState(false);
  const [ticketData, setTicketData] = useState({
    ticketNumber: "",
    summary: "",
    departName: "",
    description: "",
    assignedTo: "",
    category: "",
    closedOn: new Date(),
    createdOn: new Date(),
    createdBy: "user name",
    dueOn: new Date(),
    priority: "",
    // organization: "HiltonPharmaPvtLtd",
    status: "",
    timeSpent: 20,
    timeToResolve: 30,
    organizationHost: "HiltonPharmaPvtLtd",
    location: "",
    software: "",
  });
  const [closedOn, setClosedOn] = useState(new Date());
  const [dueOn, setDueOn] = useState(new Date());
  const [createdOn, setCreatedOn] = useState(new Date());
  const [feedbackData, setFeedbackData] = useState([]);
  const [userId, setUserId] = useState();
  const [userName, setUserName] = useState();
  const [role, setRole] = useState();
  const [userData, setUserData] = useState([]);

  const getUserData = async () => {
    // // console.log("userId getUserData ==>", userId);
    let fd = await axios.post(`${url}/user/getIndividualUser`, { id: userId });
    // // console.log("userData from getUserData ===>", fd.data[0]);
    setUserData(fd.data[0]);
  };

  useEffect(() => {
    var token = localStorage.getItem("token");
    var decoded = jwt_decode(token);
    // // console.log(decoded);
    setUserId(decoded.id);
    setUserName(decoded.username);
    setRole(decoded.isAdmin);
    getUserData();
  }, [userId]);

  //   const feedbackData = async () => await getFeedback()
  const getFeedbackData = async () => {
    const response = await getFeedback();
    // // console.log("resposne ===>", response);
    // // console.log("resposne ===>", response.data.data);
    setFeedbackData(response?.data?.data);
    return response;
  };

  useEffect(() => {
    getFeedbackData();
    clear();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      toast.warn("Please fill all fields");
      setValidated(false);
      return;
    }

    // // console.log("ticketData ====>", ticketData);
    const submitData = {
      ticketNumber: Number(lastId + 1),
      summary: ticketData.summary,
      departName: ticketData.departName,
      description: ticketData.description,
      assignedTo: ticketData.assignedTo,
      category: ticketData.category,
      closedOn: moment(closedOn).format("YYYY-MM-DD"),
      createdOn: moment().format("YYYY-MM-DD"),
      // createdBy: ticketData.createdBy,
      createdBy: userName,
      dueOn: moment(dueOn).format("YYYY-MM-DD"),
      priority: ticketData.priority,
      // organization: "HiltonPharmaPvtLtd",
      status: ticketData.status,
      timeSpent: 0,
      timeToResolve: 0,
      organizationHost: ticketData.organizationHost,
      location: ticketData.location,
      software: ticketData.software,
    };
    setValidated(true);
    // // console.log("submitData ====>", submitData);

    axios({
      method: "POST",
      data: submitData,
      url: `${url}/feedback/createFeedback`,
    })
      .then((response) => {
        // // console.log("response from createFeedback ====>", response);
        toast.success("Ticket Inserted successfully ");
        if (response.status === 200) {
          getFeedbackData();
          setValidated(false);
          setShow(false);
          clear();
        }
      })
      .catch((error) => {
        toast.error("Server Error!");
        setValidated(false);
        // console.log("error from createFeedback =====>", error);
      });
  };

  const clear = () => {
    setTicketData({
      summary: "",
      departName: "",
      description: "",
      assignedTo: "",
      category: "",
      closedOn: "",
      createdOn: "",
      createdBy: "",
      dueOn: "",
      priority: "",
      organization: "",
      status: "",
      timeSpent: "",
      timeToResolve: "",
      organizationHost: "",
      location: "",
      software: "",
    });
  };

  // // console.log("feedbackData ===>", feedbackData.length - 1);
  const [lastId, setLastId] = useState();
  // // console.log(lastId);
  // console.log("feedbackData====>", feedbackData);
  if (feedbackData > 0) {
  }
  const lastIds = (feedbackData) => {
    let lastTicket;

    feedbackData?.forEach((item, index, arr) => {
      let lastId = arr.slice(0);
      // console.log("lastId====>", lastId);
      lastTicket = lastId[0].id;
      return lastTicket;
    });
    return lastTicket;
  };
  // // console.log("lastIDS ====>", lastIds.slice(-1));
  // // console.log("lastIDS ====>", lastIds(feedbackData));
  useEffect(() => {
    if (feedbackData.length > 0) {
      setLastId(lastIds(feedbackData));
    } else {
      setLastId(0);
    }
  }, [lastIds(feedbackData)]);

  // // console.log("lastId ==> state ====>", lastId);


  return (
    // <div className="backgroundImage" style={{ height: "100vh" }}>
    <div className="" style={{ height: "100%" }}>
      {/* <NavbarCont /> */}
      {/* <div className="container-fluid mt-4">
        <div className="d-flex w-100">
          <h2 className="" style={{ fontSize: "18px", color: "black" }}>
            WELCOME TO IT SUPPORT SYSTEM!{" "}
          </h2>
        </div>
      </div> */}
      {/* Extra sesssion */}

      {/* <div className="row row-sm mt-lg-4 mt-2 mx-lg-4 " style={{ cursor: 'default' }} >
        <div className="col-sm-12 col-lg-12 col-xl-12" style={{ cursor: 'default' }}>
          <div className="card custom-card card-box" style={{ background: "#76B2B3", cursor: 'default' }}>
            {" "}
            <div className="card-body p-4" style={{ cursor: 'default' }}>
              <div className="row align-items-center" style={{ cursor: 'default' }}>
                {" "}
                <div className="" style={{ cursor: 'default' }}>
                  {" "}
                  <h4 className="d-flex justify-content-center mb-3">
                    {" "}
                    <span className="font-weight-bold text-white ">
                      Hey,<span style={{ color: "#112564" }}> {userName?.toUpperCase()}! {" "}</span>
                      WELCOME TO IT SUPPORT SYSTEM!
                    </span>{" "}
                  </h4>{" "}
                </div>
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div> */}

      <div className="row row-sm  mt-lg-4 mt-2 mx-1 ">
        <div className="col-sm-12 col-lg-12 col-xl-12">
          <div className="card bg-primary custom-card card-box mb-0">
            <div className="card-body p-4">
              <div className="row align-items-center">
                <div className="offset-xl-3 offset-sm-6 col-xl-8 col-sm-6 col-12 img-bg ">
                  <h4 className="d-flex  mb-3">
                    <span className="font-weight-bold text-white ">{userName?.toUpperCase()}!</span>
                  </h4>
                  <p className="tx-white mb-1">
                    WELCOME TO IT SUPPORT SYSTEM!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container  ">
        {/* <button className="btn btn-success">Support Ticket</button> */}
        <>
          {/* <Button variant="success" className="mt-2" onClick={() => setShow(true)}>
            + Generate Ticket
          </Button> */}

          <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                Support Ticket Form
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
                className="w-100"
              >
                <Row className="mb-3">
                  {/* <Row className="col-12"> */}
                  <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Form.Label style={{ fontWeight: "bold" }}>
                      {" "}
                      Ticket Number
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Ticket Number"
                      disabled
                      defaultValue={`#${lastId + 1}`}
                      // value={ticketData.ticketNumber}
                      onChange={(e) =>
                        setTicketData({
                          ...ticketData,
                          ticketNumber: e.target.value,
                        })
                      }
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    // md="12"
                    md="6"
                    className="w-100"
                    controlId="validationCustom04"
                  >
                    <Form.Label style={{ fontWeight: "bold" }}>
                      {" "}
                      Department
                    </Form.Label>
                    <Form.Select
                      required
                      //   defaultValue="Department Name"
                      value={ticketData.departName}
                      onChange={(e) =>
                        setTicketData({
                          ...ticketData,
                          departName: e.target.value,
                        })
                      }
                      aria-label="Default select example"
                    >
                      {/* <option>Department</option> */}
                      <option value="" selected disabled hidden>
                        Choose here
                      </option>
                      <option value="Data Centre Main">Data Centre Main</option>
                      <option value="Prepress">Prepress</option>
                      <option value="Prepress Servers">Prepress Servers</option>
                      <option value="Fianance">Fianance</option>
                      <option value="Production Office">
                        Production Office
                      </option>
                      <option value="Sliting">Sliting</option>
                      <option value="Compliance">Compliance</option>
                      <option value="Lemination">Lemination</option>
                      <option value="Labour washroom">Labour washroom</option>
                      <option value="Ink-Despensing">Ink-Despensing</option>
                      <option value="General Store">General Store</option>
                      <option value="Unit 2 Data Centre">
                        {" "}
                        Unit 2 Data Centre
                      </option>
                      <option value="Exculusion">Exculusion</option>
                      <option value="Siliting">Siliting</option>
                      <option value="Unit 2 Office">Unit 2 Office</option>
                      <option value="Recycle Office">Recycle Office</option>
                      <option value="QC">QC</option>
                      <option value="Marketing">Marketing</option>
                      <option value="IT">IT</option>
                    </Form.Select>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    {/* <Form.Control.Feedback type="invalid">
                      Please provide a valid Depart Name.
                    </Form.Control.Feedback> */}
                  </Form.Group>
                  {/* <Form.Group as={Col} md="12" controlId="validationCustom02"> */}
                  <Form.Group as={Col} md="6" controlId="validationCustom02">
                    <Form.Label style={{ fontWeight: "bold" }}>
                      {" "}
                      Summary
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Summary"
                      //   defaultValue="Summary"
                      value={ticketData.summary}
                      onChange={(e) =>
                        setTicketData({
                          ...ticketData,
                          summary: e.target.value,
                        })
                      }
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="validationCustomUsername"
                  >
                    <Form.Label style={{ fontWeight: "bold" }}>
                      Description
                    </Form.Label>
                    <FloatingLabel
                      controlId="floatingTextarea2"
                      label="Brief Message..."
                    >
                      <Form.Control
                        as="textarea"
                        placeholder="Leave a comment here"
                        style={{ height: "75px" }}
                        value={ticketData.description}
                        onChange={(e) =>
                          setTicketData({
                            ...ticketData,
                            description: e.target.value,
                          })
                        }
                      />
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="6"
                    className="w-100"
                    controlId="validationCustom03"
                  >
                    <Form.Label style={{ fontWeight: "bold" }}>
                      {" "}
                      Priority
                    </Form.Label>
                    {/* <Form.Control type="text" placeholder="Priority" required /> */}
                    <Form.Select
                      value={ticketData.priority}
                      onChange={(e) =>
                        setTicketData({
                          ...ticketData,
                          priority: e.target.value,
                        })
                      }
                      aria-label="Default select example"
                    >
                      <option value="" selected disabled hidden>
                        Choose here
                      </option>
                      <option value="High">High</option>
                      <option value="Medium">Medium</option>
                      <option value="Low">Low</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid priority.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="6"
                    className="w-100"
                    controlId="validationCustom05"
                  >
                    <Form.Label style={{ fontWeight: "bold" }}>
                      {" "}
                      Status
                    </Form.Label>
                    {/* <Form.Control type="text" placeholder="Status" required /> */}
                    <Form.Select
                      aria-label="Default select example"
                      value={ticketData.status}
                      onChange={(e) =>
                        setTicketData({ ...ticketData, status: e.target.value })
                      }
                    >
                      <option value="" selected disabled hidden>
                        Choose here
                      </option>
                      <option value="Open">Open</option>
                      <option value="Closed">Closed</option>
                      <option value="Waiting">Waiting</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid Status.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group
                    as={Col}
                    md="6"
                    className="w-100"
                    controlId="validationCustom011"
                  >
                    <Form.Label style={{ fontWeight: "bold" }}>
                      {" "}
                      Assigned To
                    </Form.Label>
                    {/* <Form.Control
                      required
                      type="text"
                      placeholder="Assigned To"
                      defaultValue="abc"
                    /> */}
                    <Form.Select
                      aria-label="Default select example"
                      value={ticketData.assignedTo}
                      onChange={(e) =>
                        setTicketData({
                          ...ticketData,
                          assignedTo: e.target.value,
                        })
                      }
                    >
                      <option value="" selected disabled hidden>
                        Choose here
                      </option>
                      <option value="Alpha">IT Help Desk</option>
                      <option value="Beta">IT Department</option>
                    </Form.Select>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="6"
                    className="w-100"
                    controlId="validationCustom012"
                  >
                    <Form.Label style={{ fontWeight: "bold" }}>
                      {" "}
                      Category
                    </Form.Label>
                    <Form.Select
                      required
                      defaultValue="abc"
                      value={ticketData.category}
                      onChange={(e) =>
                        setTicketData({
                          ...ticketData,
                          category: e.target.value,
                        })
                      }
                      aria-label="Default select example"
                    >
                      <option value="" selected disabled hidden>
                        Choose here
                      </option>
                      <option value="Windows">Windows</option>
                      <option value="MAC Address">MAC Address</option>
                      <option value="PC Hardware">PC Hardware</option>
                      <option value="Internal Hardware">
                        Internal Hardware
                      </option>
                      <option value="Other">Other</option>
                    </Form.Select>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="6"
                    className="w-100"
                    controlId="validationCustom09"
                  >
                    <Form.Label style={{ fontWeight: "bold" }}>
                      {" "}
                      Location
                    </Form.Label>
                    <Form.Select
                      type="text"
                      placeholder="Location"
                      required
                      value={ticketData.location}
                      onChange={(e) =>
                        setTicketData({
                          ...ticketData,
                          location: e.target.value,
                        })
                      }
                      aria-label="Default select example"
                    >
                      <option value="" selected disabled hidden>
                        Choose here
                      </option>
                      <option value="Unit 1">Unit 1</option>
                      <option value="Unit 2">Unit 2</option>
                      <option value="Warehouse">Warehouse</option>
                    </Form.Select>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    {/* <Form.Control.Feedback type="invalid">  Please provide a valid Location.</Form.Control.Feedback> */}
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="6"
                    className="w-100"
                    controlId="validationCustom10"
                  >
                    <Form.Label style={{ fontWeight: "bold" }}>
                      {" "}
                      Application Related To
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Application Related To"
                      required
                      value={ticketData.software}
                      onChange={(e) =>
                        setTicketData({
                          ...ticketData,
                          software: e.target.value,
                        })
                      }
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    {/* <Form.Control.Feedback type="invalid">  Please provide a valid Application.</Form.Control.Feedback> */}
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    className="w-100"
                    controlId="validationCustom01111"
                  >
                    <Form.Label style={{ fontWeight: "bold" }}>
                      {" "}
                      Created On
                    </Form.Label>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Stack spacing={3}>
                        <MobileDatePicker
                          // label="For mobile"
                          value={createdOn}
                          onChange={(newValue) => {
                            setCreatedOn(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Stack>
                    </LocalizationProvider>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="6"
                    className="w-100"
                    controlId="validationCustom0111"
                  >
                    <Form.Label style={{ fontWeight: "bold" }}>
                      {" "}
                      Closed On
                    </Form.Label>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Stack spacing={3}>
                        <MobileDatePicker
                          // label="For mobile"
                          value={closedOn}
                          onChange={(newValue) => {
                            setClosedOn(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Stack>
                    </LocalizationProvider>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="6"
                    className="w-100"
                    controlId="validationCustom0121"
                  >
                    <Form.Label style={{ fontWeight: "bold" }}>
                      {" "}
                      Due On
                    </Form.Label>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Stack spacing={2}>
                        <MobileDatePicker
                          // label="For mobile"
                          value={dueOn}
                          onChange={(newValue) => {
                            setDueOn(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Stack>
                    </LocalizationProvider>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </Row>
                {/* <Form.Group className="mb-3">
                  <Form.Check
                    required
                    label="Agree to terms and conditions"
                    feedback="You must agree before submitting."
                    feedbackType="invalid"
                  />
                </Form.Group> */}
                <div className="row mx-auto">
                  {/* <div className="row w-100"> */}
                  {/* <Button type="submit" className="w-50 ml-auto"> */}
                  <Button type="submit" className="w-100">
                    Submit
                  </Button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>
        </>
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center  h-100 for-mobile-screen">
        {/* <div className="row mt-4 d-flex justify-content-between align-items-center"> */}
        <div className="row mt-4 d-flex justify-content-around flex-wrap">
          <Fragment>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 h-auto ">
              <Link to="/myTickets">
                <Cards
                  className="cursor-pointer"
                  title="My Tickets"
                  icon="/icons/myTicket.png"
                  backgorundcolor="#9A2232"
                  color="#FFFF"
                // style={{ backgroundColor: "black" }}
                />
              </Link>
            </div>
            <div
              className="col-xl-4 col-lg-4 col-md-6 col-sm-12 h-auto "
              onClick={() => setShow(true)}
            >
              <Cards
                className="cursor-pointer"
                title="Generate Ticket"
                icon="/icons/write.png"
                backgorundcolor="#9A2232"
                color="#FFFF"
              />
            </div>
          </Fragment>
          {role === "Admin" ? (
            <Fragment>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 h-auto ">
                <Link to="/allTickets">
                  <Cards
                    className="cursor-pointer"
                    title="All Tickets"
                    icon="/icons/ticket.png"
                    color="#000"
                  />
                </Link>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 h-auto ">
                <Link to="/Assets">
                  <Cards
                    className="cursor-pointer"
                    title="Asset Management"
                    icon="/icons/asset.png"
                    color="#000"
                  />
                </Link>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 h-auto ">
                <Cards
                  className="cursor-pointer"
                  title="Access Management"
                  commingSoon="Comming Soon"
                  icon="/icons/access.png"
                  backgorundcolor="#9A2232"
                  color="#FFFF"
                />
              </div>
              {/* <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 h-auto ">
                <Cards
                  className="cursor-pointer"
                  title="Dasboard"
                  commingSoon="Comming Soon"
                  icon="/icons/dashboard.png"
                  color="#000"
                />
              </div> */}
            </Fragment>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
