import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import "./allTickets.css";

import { forwardRef } from "react";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { getFeedback, getAllFeedback } from "../../components/actions";
import { url } from "../../components/baseUrl";
import { toast } from "react-toastify";
import TestCard from "../../components/cards/TestCard";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const AllTickets = () => {
  const [feedbackData, setFeedbackData] = useState([]);
  //total tickets
  const [totalFeedback, setTotalFeedback] = useState();
  //total Open
  const [totalOpen, setTotalOpen] = useState();
  //total Closed
  const [totalClosed, setTotalClosed] = useState();
  //total waiting
  const [totalWaiting, setTotalWaiting] = useState();

  //Trigger (clear button)
  const [trigger, setTrigger] = useState(false);

  const getFeedbackData = async () => {
    const response = await getAllFeedback();
    // // console.log("resposne ===>", response);
    setFeedbackData(response?.data?.data);

    // totalTickets
    setTotalFeedback(response?.data?.data?.length);
    // totalOpen
    const totalOpeTickets = response?.data?.data?.map((item) => {
      let count = 0;
      if (item.status === "Open") {
        count++;
      }
      return count;
    });
    // // console.log("total Open Tickets", totalOpeTickets.filter(Boolean));
    setTotalOpen(totalOpeTickets.filter(Boolean)?.length);
    //totalClosed
    const totalClosedTickets = response?.data?.data?.map((item) => {
      let count = 0;
      if (item.status === "Closed") {
        count++;
      }
      return count;
    });
    // // console.log("total Closed Tickets", totalClosedTickets.filter(Boolean));
    setTotalClosed(totalClosedTickets.filter(Boolean)?.length);
    //totalWaiting
    const totalWaitingTickets = response?.data?.data?.map((item) => {
      let count = 0;
      if (item.status === "Waiting") {
        count++;
      }
      return count;
    });
    // // console.log("total waiting Tickets", totalWaitingTickets.filter(Boolean));
    setTotalWaiting(totalWaitingTickets.filter(Boolean)?.length);
    setTrigger(false);
    return response;
  };
  useEffect(() => {
    getFeedbackData();
  }, []);

  let columns = [
    {
      title: "Ticket#",
      field: "ticketNumber",
      editable: "never",
      filtering: false,
    },
    { title: "Comment", field: "comment", filtering: false },
    {
      title: "Summary",
      field: "summary",
      initialEditValue: "initial edit value",
      editable: "never",
      filtering: false,
    },
    {
      title: "Department",
      field: "departName",
      editable: "never",
      filtering: false,
    },
    {
      title: "Description",
      field: "description",
      editable: "never",
      filtering: false,
    },
    {
      title: "Priority",
      field: "priority",
      editable: "never",
      lookup: { High: "High", Medium: "Medium", Low: "Low" },
    },
    {
      title: "Status",
      field: "status",
      lookup: { Open: "Open", Closed: "Closed", Waiting: "Waiting" },
    },
    {
      title: "AssignedTo",
      field: "assignedTo",
      editable: "never",
      filtering: false,
    },
    {
      title: "Category",
      field: "category",
      editable: "never",
      filtering: false,
    },
    {
      title: "CreatedBy",
      field: "createdBy",
      editable: "never",
      filtering: false,
    },
    {
      title: "CreatedOn",
      field: "createdOn",
      editable: "never",
      filtering: false,
    },
    {
      title: "ClosedOn",
      field: "closedOn",
      editable: "never",
      filtering: false,
    },
    { title: "Due On", field: "dueOn", editable: "never", filtering: false },
    { title: "TimeSpent", field: "timeSpent", filtering: false },
    { title: "TimeToResolve", field: "timeToResolve", filtering: false },
    {
      title: "OrganizationHost",
      field: "organizationHost",
      editable: "never",
      filtering: false,
    },
    {
      title: "Location",
      field: "location",
      editable: "never",
      filtering: false,
    },
    {
      title: "Software",
      field: "software",
      editable: "never",
      filtering: false,
    },
  ];

  const getTwoDaysAgoData = async (interval) => {
    // console.log("clicked");
    let todayTicketsData = await axios.post(`${url}/feedback/getIntervalData`, {
      interval: 2,
    });
    // console.log(todayTicketsData.data.data);
    setFeedbackData(todayTicketsData?.data?.data);

    // totalTickets
    setTotalFeedback(todayTicketsData?.data?.data?.length);
    // totalOpen
    const totalOpeTickets = todayTicketsData?.data?.data?.map((item) => {
      let count = 0;
      if (item.status === "Open") {
        count++;
      }
      return count;
    });
    // console.log("total Open Tickets", totalOpeTickets.filter(Boolean));
    setTotalOpen(totalOpeTickets.filter(Boolean)?.length);
    //totalClosed
    const totalClosedTickets = todayTicketsData?.data?.data?.map((item) => {
      let count = 0;
      if (item.status === "Closed") {
        count++;
      }
      return count;
    });
    // // console.log("total Closed Tickets", totalClosedTickets.filter(Boolean));
    setTotalClosed(totalClosedTickets.filter(Boolean)?.length);
    //totalWaiting
    const totalWaitingTickets = todayTicketsData?.data?.data?.map((item) => {
      let count = 0;
      if (item.status === "Waiting") {
        count++;
      }
      return count;
    });
    // // console.log("total waiting Tickets", totalWaitingTickets.filter(Boolean));
    setTotalWaiting(totalWaitingTickets.filter(Boolean)?.length);
    setTrigger(true);

    return todayTicketsData;
  };
  const getSevenDaysAgoData = async (interval) => {
    // console.log("clicked");
    let todayTicketsData = await axios.post(`${url}/feedback/getIntervalData`, {
      interval: 7,
    });
    // console.log(todayTicketsData.data.data);
    setFeedbackData(todayTicketsData?.data?.data);

    // totalTickets
    setTotalFeedback(todayTicketsData?.data?.data?.length);
    // totalOpen
    const totalOpeTickets = todayTicketsData?.data?.data?.map((item) => {
      let count = 0;
      if (item.status === "Open") {
        count++;
      }
      return count;
    });
    // console.log("total Open Tickets", totalOpeTickets.filter(Boolean));
    setTotalOpen(totalOpeTickets.filter(Boolean)?.length);
    //totalClosed
    const totalClosedTickets = todayTicketsData?.data?.data?.map((item) => {
      let count = 0;
      if (item.status === "Closed") {
        count++;
      }
      return count;
    });
    // // console.log("total Closed Tickets", totalClosedTickets.filter(Boolean));
    setTotalClosed(totalClosedTickets.filter(Boolean)?.length);
    //totalWaiting
    const totalWaitingTickets = todayTicketsData?.data?.data?.map((item) => {
      let count = 0;
      if (item.status === "Waiting") {
        count++;
      }
      return count;
    });
    // // console.log("total waiting Tickets", totalWaitingTickets.filter(Boolean));
    setTotalWaiting(totalWaitingTickets.filter(Boolean)?.length);
    setTrigger(true);

    return todayTicketsData;
  };
  const getfifteenDaysAgoData = async (interval) => {
    // console.log("clicked");
    let todayTicketsData = await axios.post(`${url}/feedback/getIntervalData`, {
      interval: 15,
    });
    // console.log(todayTicketsData.data.data);
    setFeedbackData(todayTicketsData?.data?.data);

    // totalTickets
    setTotalFeedback(todayTicketsData?.data?.data?.length);
    // totalOpen
    const totalOpeTickets = todayTicketsData?.data?.data?.map((item) => {
      let count = 0;
      if (item.status === "Open") {
        count++;
      }
      return count;
    });
    // console.log("total Open Tickets", totalOpeTickets.filter(Boolean));
    setTotalOpen(totalOpeTickets.filter(Boolean)?.length);
    //totalClosed
    const totalClosedTickets = todayTicketsData?.data?.data?.map((item) => {
      let count = 0;
      if (item.status === "Closed") {
        count++;
      }
      return count;
    });
    // // console.log("total Closed Tickets", totalClosedTickets.filter(Boolean));
    setTotalClosed(totalClosedTickets.filter(Boolean)?.length);
    //totalWaiting
    const totalWaitingTickets = todayTicketsData?.data?.data?.map((item) => {
      let count = 0;
      if (item.status === "Waiting") {
        count++;
      }
      return count;
    });
    // // console.log("total waiting Tickets", totalWaitingTickets.filter(Boolean));
    setTotalWaiting(totalWaitingTickets.filter(Boolean)?.length);
    setTrigger(true);

    return todayTicketsData;
  };
  // const getLastTwoDays = async () => {
  //   let todayTicketsData = await getDataAccordingToInterval(2);
  //   // console.log(todayTicketsData);
  // };

  const getTodayTickets = async () => {
    // console.log("clicked");
    let todayTicketsData = await axios.get(`${url}/feedback/todayTickets`);
    // console.log(todayTicketsData.data.data);
    setFeedbackData(todayTicketsData?.data?.data);

    // totalTickets
    setTotalFeedback(todayTicketsData?.data?.data?.length);
    // totalOpen
    const totalOpeTickets = todayTicketsData?.data?.data?.map((item) => {
      let count = 0;
      if (item.status === "Open") {
        count++;
      }
      return count;
    });
    // // console.log("total Open Tickets", totalOpeTickets.filter(Boolean));
    setTotalOpen(totalOpeTickets.filter(Boolean)?.length);
    //totalClosed
    const totalClosedTickets = todayTicketsData?.data?.data?.map((item) => {
      let count = 0;
      if (item.status === "Closed") {
        count++;
      }
      return count;
    });
    // // console.log("total Closed Tickets", totalClosedTickets.filter(Boolean));
    setTotalClosed(totalClosedTickets.filter(Boolean)?.length);
    //totalWaiting
    const totalWaitingTickets = todayTicketsData?.data?.data?.map((item) => {
      let count = 0;
      if (item.status === "Waiting") {
        count++;
      }
      return count;
    });
    // // console.log("total waiting Tickets", totalWaitingTickets.filter(Boolean));
    setTotalWaiting(totalWaitingTickets.filter(Boolean)?.length);
    setTrigger(true);
  };

  // console.log(trigger);

  return (
    <div>
      <div className="all-tickets-container">
        <h1 className="px-5 py-3 text-center" style={{ color: "#000" }}>
          All Tickets
        </h1>

        {/* <div className="row">
          <TestCard
            className="card l-bg-cherry"
            icon="fas fa-shopping-cart"
            title="Total"
            number={totalFeedback ? totalFeedback : 0}
          />

          <TestCard
            className="card l-bg-blue-dark"
            icon="fas fa-shopping-cart"
            title="Closed"
            number={totalClosed ? totalClosed : 0}
          />

          <TestCard
            className="card l-bg-green-dark"
            icon="fas fa-shopping-cart"
            title="Waiting"
            number={totalWaiting ? totalWaiting : 0}
          />
          <TestCard
            className="card l-bg-orange-dark"
            icon="fas fa-shopping-cart"
            title="Open"
            number={totalOpen ? totalOpen : 0}
          />
        </div> */}
        <div className="row w-auto">
          <div className="col-lg-3 col-md-6 col-sm-12 border-black px-5 my-2">
            <div className="py-4 row d-flex justify-content-between align-items-center rounded h-100 all-tickets-card bg-total">
              <div className="col-6 w-100 mx-auto text-center px-0">
                <h5>
                  Total
                  {/* <br /> */}
                </h5>
                <h5>{totalFeedback ? totalFeedback : 0}</h5>
              </div>
              <div className="col-6 w-100 mx-auto text-center px-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={34}
                  height={34}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-layers"
                >
                  <polygon points="12 2 2 7 12 12 22 7 12 2" />
                  <polyline points="2 17 12 22 22 17" />
                  <polyline points="2 12 12 17 22 12" />
                </svg>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 border-black px-5 my-2">
            <div className="py-4 row d-flex justify-content-between align-items-center rounded h-100 all-tickets-card bg-closed">
              <div className="col-6 w-100 mx-auto text-center px-0">
                <h5>
                  Closed
                  {/* <br /> */}
                </h5>
                <h5>{totalClosed ? totalClosed : 0} </h5>
              </div>
              <div className="col-6 w-100 mx-auto text-center px-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={34}
                  height={34}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-check-circle"
                >
                  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                  <polyline points="22 4 12 14.01 9 11.01" />
                </svg>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 border-black px-5 my-2">
            <div className="py-4 row d-flex justify-content-between align-items-center rounded h-100 all-tickets-card bg-waiting">
              <div className="col-6 w-100 mx-auto text-center px-0">
                <h5>
                  Waiting
                  {/* <br /> */}
                </h5>
                <h5>{totalWaiting ? totalWaiting : 0}</h5>
              </div>
              <div className="col-6 w-100 mx-auto text-center px-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={34}
                  height={34}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-clock"
                >
                  <circle cx={12} cy={12} r={10} />
                  <polyline points="12 6 12 12 16 14" />
                </svg>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 border-black px-5 my-2">
            <div className="py-4 row d-flex justify-content-between align-items-center rounded h-100 all-tickets-card bg-open">
              <div className="col-6 w-100 mx-auto text-center px-0">
                <h5>
                  Open
                  {/* <br /> */}
                </h5>
                <h5>{totalOpen ? totalOpen : 0}</h5>
              </div>
              <div className="col-6 w-100 mx-auto text-center px-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={34}
                  height={34}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-repeat"
                >
                  <polyline points="17 1 21 5 17 9" />
                  <path d="M3 11V9a4 4 0 0 1 4-4h14" />
                  <polyline points="7 23 3 19 7 15" />
                  <path d="M21 13v2a4 4 0 0 1-4 4H3" />
                </svg>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end  ">
            <button
              onClick={getTodayTickets}
              className="btn ml-2"
              style={{ background: "#9A2232" }}
            >
              <div className="mx-2" style={{ fontSize: "12px", color: "#fff" }}>
                {" "}
                Today Tickets{" "}
              </div>
            </button>
            <button
              onClick={getTwoDaysAgoData}
              className="btn ml-2"
              style={{ background: "#9A2232" }}
            >
              <div className="mx-2" style={{ fontSize: "12px", color: "#fff" }}>
                {" "}
                Last 2 Days{" "}
              </div>
            </button>
            <button
              onClick={getSevenDaysAgoData}
              className="btn ml-2"
              style={{ background: "#9A2232" }}
            >
              <div className="mx-2" style={{ fontSize: "12px", color: "#fff" }}>
                {" "}
                Last 7 Days{" "}
              </div>
            </button>
            <button
              onClick={getfifteenDaysAgoData}
              className="btn ml-2"
              style={{ background: "#9A2232" }}
            >
              <div className="mx-2" style={{ fontSize: "12px", color: "#fff" }}>
                {" "}
                Last 15 Days{" "}
              </div>
            </button>

            {trigger ? (
              <button onClick={getFeedbackData} className="btn btn-danger ml-2">
                <div className="mx-2" style={{ fontSize: "12px" }}>
                  Clear
                </div>
              </button>
            ) : null}
          </div>
        </div>
      </div>
      <div className="all-tickets-container my-2">
        <MaterialTable
          title="All Tickets"
          columns={columns}
          icons={tableIcons}
          //   data={data}
          data={feedbackData}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const dataUpdate = [...feedbackData];
                  const index = oldData.id;
                  dataUpdate[index] = newData;
                  axios
                    .post(`${url}/feedback/updateFeedback`, newData)
                    .then((res) => {
                      if (res.status === 200) {
                        // console.log("Ticket updated Successfully");
                        // console.log(newData);
                        getFeedbackData();
                        toast.success("Ticket Update Successfully");
                      }
                    })
                    .catch((error) => {
                      // error from catch
                      // console.log("error from catch ==>", error);
                      if (error.message === "Network Error") {
                        // console.log("Network Error");
                        toast.error("Server Error!");
                        return;
                      } else if (error.response.status === 409) {
                        // console.log("Ticket Already Exist");
                        return;
                      } else {
                        // console.log("error");
                        toast.error("Error !");
                        return;
                      }
                    });

                  resolve();
                }, 1000);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const id = oldData.id;
                  axios
                    .post(`${url}/feedback/deleteFeedback`, {
                      id: id,
                    })
                    .then((res) => {
                      if (res.status === 200) {
                        // console.log("delted Successfully");
                        getFeedbackData();
                        toast.info("Ticket Delete Successfully");
                      } else {
                        // console.log(res.data);
                        toast.error("Error !");
                      }
                    })
                    .catch((error) => {
                      // error from catch
                      // console.log("error from catch ==>", error);
                      if (error.message === "Network Error") {
                        // console.log("Server Error!");
                        // toast.error("Server Error!");
                        // setIsDisabled(false)
                        return;
                      } else {
                        // console.log("error");
                        // toast.error("Error !");
                        return;
                      }
                    });
                  resolve();
                }, 1000);
              }),
          }}
          options={{
            filtering: true,
            pageSizeOptions: [
              5,
              10,
              20,
              { value: feedbackData.length, label: "All" },
            ],
            headerStyle: {
              backgroundColor: "#9A2232",
              color: "#FFF",
            },
          }}
        />
      </div>
    </div>
  );
};

export default AllTickets;
