import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getFeedback } from "../../components/actions";
import TicketBox from "../../components/ticket-box/TicketBox";
import "./myTickets.css";

const MyTickets = () => {
  const [state, setState] = useState([
    {
      Ticket: "995",
      Summary: "New PBX Install",
      Description: "Regards",
      AssignedTo: "Muhammad Zeeshan",
      Category: "IP Phone",
      ClosedOn: "1/24/2022 4:20 am UTC",
      CreatedOn: "1/24/2022 4:18 am UTC",
      CreatedBy: "Muhammad Zeeshan",
      // DueOn:
      Priority: "medium",
      OrganizationName: "HiltonPharmaPvtLtd",
      Status: "closed",
      TimeSpent: "30",
      TimeToResolve: "1",
      OrganizationHost: "HiltonPharmaPvtLtd",
      LinktoTicket: "https://help-desk-server/tickets/closed/996",
      Location: "HO",
      Software: "SAP",
    },
    {
      Ticket: "996",
      Summary: "FINANCE PRINTER",
      Description: "DEAR TEAM",
      AssignedTo: "Hammad Karim",
      Category: "Printer",
      ClosedOn: "1/24/2022 4:20 am UTC",
      CreatedOn: "1/24/2022 4:18 am UTC",
      CreatedBy: "Muhammad Yasir",
      // DueOn:
      Priority: "medium",
      OrganizationName: "HiltonPharmaPvtLtd",
      Status: "open",
      TimeSpent: "30",
      TimeToResolve: "1",
      OrganizationHost: "HiltonPharmaPvtLtd",
      LinktoTicket: "https://help-desk-server/tickets/closed/996",
      Location: "HO",
      Software: "SAP",
    },
    {
      Ticket: "997",
      Summary: "FINANCE PRINTER",
      Description: "DEAR TEAM",
      AssignedTo: "Hammad Karim",
      Category: "Printer",
      ClosedOn: "1/24/2022 4:20 am UTC",
      CreatedOn: "1/24/2022 4:18 am UTC",
      CreatedBy: "Muhammad Yasir",
      // DueOn:
      Priority: "medium",
      OrganizationName: "HiltonPharmaPvtLtd",
      Status: "waiting",
      TimeSpent: "30",
      TimeToResolve: "1",
      OrganizationHost: "HiltonPharmaPvtLtd",
      LinktoTicket: "https://help-desk-server/tickets/closed/996",
      Location: "HO",
      Software: "SAP",
    },
    {
      Ticket: "998",
      Summary: "FINANCE ",
      Description: "TEAM",
      AssignedTo: "Karim",
      Category: "Printer",
      ClosedOn: "1/24/2022 4:20 am UTC",
      CreatedOn: "1/24/2022 4:18 am UTC",
      CreatedBy: "Muhammad Yasir",
      // DueOn:
      Priority: "medium",
      OrganizationName: "HiltonPharmaPvtLtd",
      Status: "open",
      TimeSpent: "30",
      TimeToResolve: "1",
      OrganizationHost: "HiltonPharmaPvtLtd",
      LinktoTicket: "https://help-desk-server/tickets/closed/996",
      Location: "HO",
      Software: "SAP",
    },
  ]);

  const [feedbackData, setFeedbackData] = useState([]);
  const getFeedbackData = async () => {
    const response = await getFeedback();
    // console.log("resposne ===>", response);
    setFeedbackData(response?.data?.data);
    return response;
  };
  useEffect(() => {
    getFeedbackData();
  }, []);

  // console.log("feedbackData ====>", feedbackData);

  return (
    <div>
      <div className="row my-3">
        <h1 className="text-center" style={{ color: "#000" }}>
          My Tickets
        </h1>
      </div>
      <div className="row mx-auto">
        {/* {state.map((item) => { */}
        {feedbackData.map((item) => {
          //   // console.log("item ===>", item);
          return (
            <div className="col-xl-3 col-lg-4 col-md-6 px-0" key={item}>
              <TicketBox
                ticketNo={item.ticketNumber}
                ticketTitle={item.summary}
                departName={item.departName}
                description={item.description}
                assignedTo={item.assignedTo}
                category={item.category}
                closedOn={item.closedOn}
                createdOn={item.createdOn}
                createdBy={item.createdBy}
                dueOn={item.dueOn}
                priority={item.priority}
                organization={item.organization}
                status={item.status}
                timeSpent={item.timeSpent}
                timeToResolve={item.timeToResolve}
                organizationHost={item.organizationHost}
                location={item.location}
                software={item.software}
              // ticketNo={item.Ticket}
              // ticketTitle={item.Summary}
              // priority={item.Priority}
              // status={item.Status}
              // createdBy={item.CreatedBy}
              // closedOn={item.ClosedOn}
              // createdOn={item.CreatedOn}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MyTickets;
