import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import moment from "moment";
import jwt_decode from "jwt-decode";

import { forwardRef } from "react";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { getAssets } from "../../components/actions";
import { url } from "../../components/baseUrl";
import { toast } from "react-toastify";
import { Button, Modal, Form, Row, Col, FloatingLabel } from "react-bootstrap";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const Asset = () => {
  const [AssetData, setAssetData] = useState([]);
  //total tickets
  const [totalAsset, setTotalAsset] = useState();
  // //total Open
  // const [totalOpen, setTotalOpen] = useState();
  // //total Closed
  // const [totalClosed, setTotalClosed] = useState();
  // //total waiting
  // const [totalWaiting, setTotalWaiting] = useState();

  //Trigger (clear button)
  const [trigger, setTrigger] = useState(false);

  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [assets, setAssets] = useState({
    assetNo: 0,
    deviceType: "",
    deviceModel: "",
    deviceOS: "",
    deviceOwner: "",
    deviceSerial: "",
    deviceHardDrive: "",
    deviceRAM: "",
    deviceProcessor: "",
    deviceMAC: "",
    LoggedDate: "",
    LoggedBy: "",
  });
  const getAssetData = async () => {
    const response = await getAssets();
    // // console.log("resposne ===>", response);
    setAssetData(response?.data?.data);
    setTrigger(false);
    return response;
  };
  useEffect(() => {
    var token = localStorage.getItem("token");
    var decoded = jwt_decode(token);
    setAssets({
      ...assets,
      LoggedBy: decoded.username,
    });
    getAssetData();
  }, []);

  let columns = [
    { title: "Asset No", field: "assetNo", filtering: false },
    {
      title: "Device Type",
      field: "deviceType",
      editable: "never",
      filtering: false,
    },
    { title: "Device Brand", field: "deviceModel", filtering: false },
    {
      title: "Device OS",
      field: "deviceOS",
      initialEditValue: "initial edit value",
      editable: "never",
      filtering: false,
    },
    {
      title: "Device Owner",
      field: "deviceOwner",
      editable: "never",
      filtering: false,
    },
    {
      title: "Device Serial",
      field: "deviceSerial",
      editable: "never",
      filtering: false,
    },
    {
      title: "Device HDD",
      field: "deviceHardDrive",
      editable: "never",
      filtering: false,
    },
    {
      title: "Device RAM",
      field: "deviceRAM",
      filtering: false,
    },
    {
      title: "Device Processor",
      field: "deviceProcessor",
      editable: "never",
      filtering: false,
    },
    {
      title: "Device MAC",
      field: "deviceMAC",
      editable: "never",
      filtering: false,
    },
    {
      title: "Logged Date",
      field: "LoggedDate",
      editable: "never",
      filtering: false,
    },
    {
      title: "Logged By",
      field: "LoggedBy",
      editable: "never",
      filtering: false,
    },
  ];
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      toast.warn("Please fill all fields");
      setValidated(false);
      return;
    }

    const submitData = {
      assetNo: assets.assetNo,
      deviceType: assets.deviceType,
      deviceModel: assets.deviceModel,
      deviceOS: assets.deviceOS,
      deviceOwner: assets.deviceOwner,
      deviceSerial: assets.deviceSerial,
      deviceHardDrive: assets.deviceHardDrive + "GB",
      deviceRAM: assets.deviceRAM,
      deviceProcessor: assets.deviceProcessor,
      deviceMAC: assets.deviceMAC,
      LoggedDate: moment().format("L"),
      LoggedBy: assets.LoggedBy,
    };
    setValidated(true);
    // console.log("submitData ====>", submitData);

    axios({
      method: "POST",
      data: submitData,
      url: `${url}/asset/createAsset`,
    })
      .then((response) => {
        toast.success("Ticket Inserted successfully ");
        if (response.status === 200) {
          getAssetData();
          setValidated(false);
          setShow(false);
          clear();
        }
      })
      .catch((error) => {
        toast.error("Server Error!");
        setValidated(false);
      });
  };

  const clear = () => {
    setAssets({
      assetNo: 0,
      deviceType: "",
      deviceModel: "",
      deviceOS: "",
      deviceOwner: "",
      deviceSerial: "",
      deviceHardDrive: "",
      deviceRAM: "",
      deviceProcessor: "",
      deviceMAC: "",
      LoggedDate: "",
      LoggedBy: "",
    });
  };
  // console.log(trigger);

  return (
    <div>
      <div className="all-tickets-container">
        <div className="container  ">
          <>
            <Modal
              show={show}
              onHide={() => setShow(false)}
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                  Asset Management Form
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmit}
                  className="w-100"
                >
                  <Row className="mb-3">
                    <Form.Group
                      as={Col}
                      md="6"
                      className="w-100"
                      controlId="validationCustom01"
                    >
                      <Form.Label style={{ fontWeight: "bold" }}>
                        {" "}
                        Asset No.
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Asset No"
                        required
                        value={assets.assetNo}
                        onChange={(e) =>
                          setAssets({
                            ...assets,
                            assetNo: e.target.value,
                          })
                        }
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      md="6"
                      className="w-100"
                      controlId="validationCustom011"
                    >
                      <Form.Label style={{ fontWeight: "bold" }}>
                        {" "}
                        Device Type
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        value={assets.deviceType}
                        onChange={(e) =>
                          setAssets({
                            ...assets,
                            deviceType: e.target.value,
                          })
                        }
                      >
                        <option value="" selected disabled hidden>
                          Choose Type
                        </option>
                        <option value="Desktop">Desktop</option>
                        <option value="Laptop">Laptop</option>
                      </Form.Select>
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6"
                      className="w-100"
                      controlId="validationCustom10"
                    >
                      <Form.Label style={{ fontWeight: "bold" }}>
                        {" "}
                        Device Owner
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Owner Name"
                        required
                        value={assets.deviceOwner}
                        onChange={(e) =>
                          setAssets({
                            ...assets,
                            deviceOwner: e.target.value,
                          })
                        }
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6"
                      className="w-100"
                      controlId="validationCustom012"
                    >
                      <Form.Label style={{ fontWeight: "bold" }}>
                        {" "}
                        Device Brand
                      </Form.Label>
                      <Form.Select
                        required
                        defaultValue="abc"
                        value={assets.deviceModel}
                        onChange={(e) =>
                          setAssets({
                            ...assets,
                            deviceModel: e.target.value,
                          })
                        }
                        aria-label="Default select example"
                      >
                        <option value="" selected disabled hidden>
                          Choose Brand
                        </option>
                        <option value="Dell">Dell</option>
                        <option value="HP">HP</option>
                        <option value="Lenovo">Lenovo</option>
                        <option value="Apple">Apple</option>
                        <option value="Acer">Acer</option>
                        <option value="Asus">Asus</option>
                        <option value="Samsung">Samsung</option>
                        <option value="LG">LG</option>
                        <option value="Xiaomi">Xiaomi</option>
                        <option value="Other">Other</option>
                      </Form.Select>
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6"
                      className="w-100"
                      controlId="validationCustom012"
                    >
                      <Form.Label style={{ fontWeight: "bold" }}>
                        {" "}
                        Device Operating Sytem
                      </Form.Label>
                      <Form.Select
                        required
                        defaultValue="abc"
                        value={assets.deviceOS}
                        onChange={(e) =>
                          setAssets({
                            ...assets,
                            deviceOS: e.target.value,
                          })
                        }
                        aria-label="Default select example"
                      >
                        <option value="" selected disabled hidden>
                          Choose OS
                        </option>
                        <option value="Windows">Windows</option>
                        <option value="macOS">macOS</option>
                        <option value="Linux">Linux</option>
                      </Form.Select>
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6"
                      className="w-100"
                      controlId="validationCustom10"
                    >
                      <Form.Label style={{ fontWeight: "bold" }}>
                        {" "}
                        Device Serial Number
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Serial Number"
                        required
                        value={assets.deviceSerial}
                        onChange={(e) =>
                          setAssets({
                            ...assets,
                            deviceSerial: e.target.value,
                          })
                        }
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6"
                      className="w-100"
                      controlId="validationCustom012"
                    >
                      <Form.Label style={{ fontWeight: "bold" }}>
                        {" "}
                        Device RAM
                      </Form.Label>
                      <Form.Select
                        required
                        defaultValue="abc"
                        value={assets.deviceRAM}
                        onChange={(e) =>
                          setAssets({
                            ...assets,
                            deviceRAM: e.target.value,
                          })
                        }
                        aria-label="Default select example"
                      >
                        <option value="" selected disabled hidden>
                          Choose RAM
                        </option>
                        <option value="1GB">1GB</option>
                        <option value="2GB">2GB</option>
                        <option value="4GB">4GB</option>
                        <option value="8GB">8GB</option>
                        <option value="16GB">16GB</option>
                        <option value="32GB">32GB</option>
                        <option value="64GB">64GB</option>
                      </Form.Select>
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6"
                      className="w-100"
                      controlId="validationCustom012"
                    >
                      <Form.Label style={{ fontWeight: "bold" }}>
                        {" "}
                        HDD/SSD Storage
                      </Form.Label>
                      <div className="input-group mb-3">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Storage"
                          required
                          id="validationCustom02"
                          value={assets.deviceHardDrive}
                          onChange={(e) =>
                            setAssets({
                              ...assets,
                              deviceHardDrive: e.target.value,
                            })
                          }
                        />
                        <span
                          className="input-group-text"
                          id="basic-addon2"
                          style={{ fontSize: "10px" }}
                        >
                          GB
                        </span>
                      </div>
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      md="6"
                      className="w-100"
                      controlId="validationCustom10"
                    >
                      <Form.Label style={{ fontWeight: "bold" }}>
                        {" "}
                        Device Processor
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Device Processor"
                        required
                        value={assets.deviceProcessor}
                        onChange={(e) =>
                          setAssets({
                            ...assets,
                            deviceProcessor: e.target.value,
                          })
                        }
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="12"
                      className="w-100"
                      controlId="validationCustom10"
                    >
                      <Form.Label style={{ fontWeight: "bold" }}>
                        {" "}
                        Device M.A.C Address
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Device M.A.C Address"
                        required
                        value={assets.deviceMAC}
                        onChange={(e) =>
                          setAssets({
                            ...assets,
                            deviceMAC: e.target.value,
                          })
                        }
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <div className="row mx-auto">
                    <Button type="submit" className="w-100">
                      Submit
                    </Button>
                  </div>
                </Form>
              </Modal.Body>
            </Modal>
          </>
        </div>
        <h1 className="px-5 py-3 text-center" style={{ color: "#000" }}>
          Asset Management
        </h1>
        <button
          className="btn btn-info "
          style={{ backgroundColor: "#9A2232", borderColor: "#9A2232" }}
          onClick={() => setShow(true)}
        >
          <div className="mx-2" style={{ fontSize: "13px" }}>
            Create Asset
          </div>
        </button>
      </div>
      <div className="all-tickets-container my-2">
        <MaterialTable
          title="All Tickets"
          columns={columns}
          icons={tableIcons}
          //   data={data}
          data={AssetData}
          editable={{
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const id = oldData.id;
                  axios
                    .post(`${url}/asset/deleteAsset`, {
                      id: id,
                    })
                    .then((res) => {
                      if (res.status === 200) {
                        // console.log("delted Successfully");
                        getAssetData();
                        toast.info("Asset Delete Successfully");
                      } else {
                        // console.log(res.data);
                        toast.error("Error !");
                      }
                    })
                    .catch((error) => {
                      // error from catch
                      // console.log("error from catch ==>", error);
                      if (error.message === "Network Error") {
                        // console.log("Server Error!");
                        return;
                      } else {
                        // console.log("error");
                        return;
                      }
                    });
                  resolve();
                }, 1000);
              }),
          }}
          options={{
            filtering: true,
            pageSizeOptions: [
              5,
              10,
              20,
              { value: AssetData.length, label: "All" },
            ],
            headerStyle: {
              backgroundColor: "#9A2232",
              color: "#FFF",
            },
          }}
        />
      </div>
    </div>
  );
};

export default Asset;
