import React from "react";
import { Card } from "react-bootstrap";
import "./cards.css";

const Cards = (props) => {
  return (
    // <Card
    //   style={{ width: "18rem" }}
    //   className="mb-2 mx-auto cursor-pointer feature_item border-white"
    // >
    //   <Card.Body className="mx-2 d-flex flex-column justify-content-center align-items-center">
    //     <div>
    //       {props.title}
    //       <br />
    //       <div style={{ fontSize: "12px", color: "gray" }}>
    //         {props.commingSoon ? (
    //           <Card.Text>{props.commingSoon}</Card.Text>
    //         ) : null}
    //       </div>
    //     </div>
    //   </Card.Body>
    // </Card>
    <Card
      style={{ width: "18rem" }}
      className="mb-2 mx-auto cursor-pointer feature_item border-white"
    >
      <Card.Body className="mx-2 d-flex flex-column justify-content-center align-items-center">
        <Card.Title className="text-center card-props-title d-flex justify-content-between align-items-center h-25">
          {<img src={props.icon} width="64px"></img>}{" "}{props.title}{" "}
        </Card.Title>
        <br />
        {props.commingSoon ? <Card.Text>{props.commingSoon}</Card.Text> : null}
      </Card.Body>
    </Card>
  );
};

export default Cards;
