import React, { useEffect } from "react";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

// import logo from "../../assets/kompass_logo.png";
// import logoBW from "../../assets/kompass_logo_bw.png";
import logo from "../../assets/logo-u.png";

const NavbarCont = () => {
  const [userName, setUserName] = useState("");
  let navigate = useNavigate();

  const handleLogout = () => {
    localStorage.setItem("token", "");

    window.location.reload(false);
    navigate(`/`);
  };
  useEffect(() => {
    let userName;
    setUserName(localStorage.getItem("username"));
  }, []);
  return (
    <div>
      <Navbar
        collapseOnSelect
        expand="lg"
        // bg="warning" variant="primary"
        style={{
          backgroundColor: "#9A2232",
          boxShadow: "3px 3px 3px 3px #9A2232 ",
        }}
      >
        {/* <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark"> */}
        <div className="container-fluid">
          {/* <Navbar.Brand href="#home">IT Help Desk</Navbar.Brand> */}
          <Navbar.Brand href="/">
            <img src={logo} alt="logo" height={50} />
            {/* <img src={logoBW} alt="logo" /> */}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              <div className="d-flex align-items-center">
                <Nav.Link eventKey={2}>
                  <div
                    className=""
                    style={{ color: "white", fontSize: "22px" }}
                  >
                    {userName}
                  </div>
                </Nav.Link>
                <Nav.Link eventKey={2} onClick={handleLogout}>
                  <div className="d-flex">
                    <button
                      className="btn btn-info btn-block d-flex rounded-circle ms-auto"
                      style={{
                        width: "50px",
                        height: "50px",
                        backgroundColor: "#fff",
                        borderColor: "#fff",
                      }}
                    >
                      {/* <div className="mx-2" style={{fontSize:"18px"}}>Logout </div> */}
                      {/* <div className="h-100 d-flex align-items-center"></div> */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-log-out"
                        style={{ color: "#9A2232" }}
                      >
                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                        <polyline points="16 17 21 12 16 7" />
                        <line x1={21} y1={12} x2={9} y2={12} />
                      </svg>
                    </button>
                  </div>
                </Nav.Link>
              </div>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </div>
  );
};

export default NavbarCont;
