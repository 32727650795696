import axios from "axios";
import { url } from "../baseUrl/index";

export const getFeedback = async () => {
  const getFeedbackData = await axios.post(
    `${url}/feedback/selectUserFeedback`,
    {
      userName: localStorage.getItem("username"),
    }
  );
  return getFeedbackData;
};
export const getAllFeedback = async () => {
  const getFeedbackData = await axios.get(`${url}/feedback/selectFeedback`);
  return getFeedbackData;
};

export const getAssets = async () => {
  const getAssetData = await axios.get(`${url}/asset/selectAsset`);
  return getAssetData;
};
