import { Fragment, useEffect, useState } from "react";
// import Signup from "./containers/signup/Signup";
import "./App.css";
import Login from "./containers/login/Login";
import Dashboard from "./containers/dashboard/Dashboard";
import { Routes, Route } from "react-router-dom";
import MyTickets from "./containers/myTickets/MyTickets";
import AllTickets from "./containers/allTickets/AllTickets";
import Asset from "./containers/AssestManagement/Asset";
import NavbarCont from "./components/navbar/NavbarCont";

//toastify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [isToken, setIsToken] = useState(false);
  // console.log("isToken ====>", isToken);
  const t = localStorage.getItem("token");
  // console.log("t ==>", t);
  useEffect(() => {
    if (localStorage.getItem("token")) {
      setIsToken(true);
    } else {
      setIsToken(false);
    }
  }, [t]);

  return (
    <Fragment>
      {!isToken ? (
        <Routes>
          <Route exact path="/" element={<Login />} />
          {/* <Route path="/signup" element={<Signup />} /> */}
          <Route path="*" element={<Login />} />
        </Routes>
      ) : null}
      {isToken ? (
        <div className=" main-container h-auto w-100">
          <NavbarCont />
          <Routes>
            <Route exact path="/" element={<Dashboard />} />
            <Route path="/myTickets" element={<MyTickets />} />
            <Route path="/allTickets" element={<AllTickets />} />
            <Route path="/Assets" element={<Asset />} />
            {/* <Route path="/actionTracking" element={<AllTickets />} /> */}
          </Routes>
        </div>
      ) : null}

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Fragment>
  );
}

export default App;
